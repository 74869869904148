
import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import Uniter from "assets/uniter.png"

export default function HomepageReviewCards() {
  return (
    <div>
      <p className="font-secondary font-medium text-17 text-midBlue">
        See what our clients have to say about us:
      </p>
      <div className="mainContainer w-full flex flex-col my-4 gap-5">
        <a href="https://www.trustpilot.com/users/60db02325f811c001128d575" target="_blank">
          <div className="cardOne  bg-white rounded-2xl lg:w-[459px] w-full p-5  lg:mb-0 shadow-reviewCard flex flex-col justify-between">
            <p className=" font-opensans font-bold text-17 text-darkBlue">
            "Thanks to the company and team of Tomedes"
            </p>
            <div className="name mt-6 flex justify-between">
              <p className="font-opensans font-semibold text-17 text-[#313e5a]">
                Vicki
              </p>
              <img src={Uniter} alt="uniter_logo" />
            
            </div>
          </div>
        </a>
        <a href="https://www.trustpilot.com/users/60945782a8fc63001b6f847b" target="_blank">
          <div className="cardTwo bg-white rounded-2xl lg:w-[459px] w-full p-5  lg:mb-0 shadow-reviewCard flex flex-col justify-between">
            <p className=" font-opensans font-bold text-17 text-darkBlue">
              "Tomedes has been nothing but kind, knowledgeable and efficient!"
            </p>
            <div className="name mt-6 flex justify-between">
              <p className="font-opensans font-semibold text-17 text-[#313e5a]">
                Ashrena Ali
              </p>
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/1.png"
                alt=""
                className="w-9 h-9 mb-2"
              />
            </div>
          </div>
        </a>
        <a href="https://www.trustpilot.com/reviews/61b11ffd2b49257fe29a48fa" target="_blank">
          <div className="cardThree bg-white rounded-2xl lg:w-[459px] w-full p-5 shadow-reviewCard flex flex-col justify-between">
            <p className=" font-opensans font-bold text-17 text-darkBlue">
            "Tomedes is a high quality translation provider."
            </p>
            <div className="name mt-6 flex justify-between">
              <p className="font-opensans font-semibold text-17 text-[#313e5a]">
                Jorge Castro
              </p>
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/code.svg"
                alt="code"
                className="w-9 h-9 mb-2"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}
