import React, { useRef, useState } from "react";

import { CountryArray } from "utils/constants";
import { useForm } from "react-hook-form";
import { useContact } from "hooks/Forms/useContact";
import Loader from "components/loader";
import Modal from "components/popup";
import Google from "assets/reviews/google.svg";
import Microsoft from "assets/reviews/microsoftb.svg";
import Adidas from "assets/reviews/adidasb.svg";
import Ikea from "assets/reviews/ikea.svg";
import MGM from "assets/reviews/unitednation.svg";
import Nvidia from "assets//nvidia_white.svg";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import SEO from "components/seo";
import CopyIcon from "assets/copy.png";
import { useLocation } from "@reach/router";
import { Tooltip } from "react-tooltip";

import PhoneIcon from "assets/phone.svg";
import MsgIcon from "assets/message.svg";
import ThankYouPopup from "components/popup/thankYouPopup";

import "react-phone-input-2/lib/style.css";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import ContactForm from "./contactForm";

export default function FirstContact({ styleOne }) {
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(false);
  const [phonenumber, setPhonenumber] = useState("");
  const [countrycode, setCountrycode] = useState("1");
  const captchaRef = useRef(null);
  const { status, mutateAsync } = useContact();
  const location = useLocation();
  const [copy, setCopy] = useState("Copy");
  const [captchaVisible, setCaptchaVisible] = useState(false);
  console.log(styleOne, "stylone");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // if (token) {
    const postData1 = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      country_id: data.country_id,
      // country_code: countrycode,
      phone: phonenumber,
      company: data.company,
      message: data.message,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };

    mutateAsync(postData).then((_) => {
      setOpen(true);
      reset();
      setPhonenumber("");
    });
    // } else {
    //   alert("Please verify captcha");
    // }
  };

  const handleVerify = () => {
    setToken(true);
  };

  const handleCopy = (id) => {
    const textToCopy = document.getElementById(id);
    if (textToCopy) {
      // Copy the text content to the clipboard
      navigator.clipboard
        .writeText(textToCopy.textContent)
        .then(() => {
          setCopy("Copied");
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    }
    setTimeout(() => {
      setCopy("Copy");
    }, 2000);
  };

  return (
    <>
      {styleOne == "1" ? (
        <div className="bg-new-contact-banner bg-no-repeat bg-cover py-6 sm:py-16 ">
          <div className="lg:flex justify-between  max-w-7xl mx-auto">
            <div className="left-section   lg:px-4 2xl:px-0 md:px-8 px-6  sm:flex hidden sm:items-center sm:justify-center lg:items-start">
              <div className="sm:max-w-2xl max-w-full">
                <h1 className="font-secondary text-[#00173A] sm:text-center lg:text-start  font-extrabold text-[30px] sm:text-[40px]">
                  Contact us - Our response time averages five minutes.
                </h1>
                <p className="text-[#00173A] font-opensans  sm:text-center lg:text-start leading-7 xl:w-[645px] text-base pt-4">
                  Our 24/7 customer support team is always ready to assist with
                  whatever questions or concerns you have about our services.
                  You can contact us via chat, email, or phone, and we will get
                  in touch with you immediately. We're here to help you with
                  whatever you need.
                </p>
                <div className="sm:flex sm:items-center sm:justify-center lg:items-start lg:justify-start hidden items-center gap-x-6 pt-8">
                  <img src={PhoneIcon} alt="" />
                  <span className="text-[#00173A] font-opensans text-base flex items-center">
                    <a href="tel:+19852390142">
                      US: <span id="number1">+1 985 239 0142</span>
                    </a>{" "}
                    &nbsp;
                    <img
                      src={CopyIcon}
                      className="ml-2.5 cursor-pointer"
                      onClick={() => handleCopy("number1")}
                      data-tooltip-id="copy-tooltip"
                      data-tooltip-content={copy}
                    />
                    &nbsp;{" "}
                    <a href="tel:+441615096140" className="ml-5">
                      UK: <span id="number2">+44 (0)16 1509 6140</span>
                    </a>
                    <img
                      src={CopyIcon}
                      className="ml-2.5 cursor-pointer"
                      onClick={() => handleCopy("number2")}
                      data-tooltip-id="copy-tooltip"
                      data-tooltip-content={copy}
                    />
                  </span>
                </div>
                {/* <div className="sm:hidden flex items-center gap-x-6 pt-8 ">
                <img src={PhoneIcon} alt="" />
                <div className="text-[#00173A] font-opensans text-base flex flex-col">
                  <a href="tel:+19852390142">US: +1 985 239 0142</a>
                  <a href="tel:+441615096140">UK: +44 (0)16 1509 6140</a>
                </div>
              </div> */}
                <div className="sm:flex  sm:items-center sm:justify-center lg:items-start lg:justify-start hidden items-center pt-4">
                  <img src={MsgIcon} alt="" />
                  <span className="text-[#00173A] font-opensans  text-base">
                    <a
                      href="mailto:support@tomedes.com"
                      className="ml-8"
                      id="email"
                    >
                      support@tomedes.com
                    </a>
                  </span>
                  <img
                    src={CopyIcon}
                    className="ml-2.5 cursor-pointer"
                    onClick={() => handleCopy("email")}
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content={copy}
                  />
                </div>
                <div className="mt-12 hidden sm:block">
                  <p className="text-[#00173A] sm:text-center lg:text-start font-opensans text-sm">
                    Trusted by Satisfied Clients Worldwide
                  </p>
                  <div className="top-3 mt-4 flex flex-wrap w-full  items-center sm:justify-center lg:justify-start gap-12  lg:gap-10">
                    <img
                      src={Google}
                      alt="google"
                      className="w-[79px] h-[26px]"
                    />
                    <img src={Microsoft} alt="microsoft" />
                    <img src={Adidas} alt="adidas" />
                    <img src={Ikea} alt="ikea" className="w-[56px] h-[22px]" />
                    <img src={MGM} alt="mgm" />
                    <img src={Nvidia} alt="nvidia" className="w-[80px]" />
                  </div>
                </div>
                <Tooltip
                  id="copy-tooltip"
                  place="top"
                  multiline={true}
                  className="z-50"
                />
              </div>
            </div>
            <div className="sm:hidden block left-section lg:max-w-2xl max-w-full lg:px-4 2xl:px-0 md:px-8 px-6">
              <h1 className="font-secondary text-[#00173A] font-extrabold text-[40px]">
                Contact us
              </h1>
              <p className="text-[#00173A] font-opensans leading-7 md:w-[645px] text-base mt-2">
                Our response time averages five minutes.
              </p>
            </div>
            <div className="right-section sm:flex sm:items-center lg:items-start sm:justify-center  lg:pt-0 md:pt-14 pt-8 lg:px-4 2xl:px-0 md:px-8 px-6">
              <ThankYouPopup
                open={open}
                setOpen={setOpen}
                headingPoppins
                paraOpenSans
              />

              <ContactForm
                open={open}
                setOpen={setOpen}
                phonenumber={phonenumber}
                setPhonenumber={setPhonenumber}
                status={status}
                mutateAsync={mutateAsync}
                location={location}
                captchaVisible={captchaVisible}
                setCaptchaVisible={setCaptchaVisible}
                lebeltextColour="[#00173A]"
              />
              <div className="sm:hidden flex flex-col pt-8">
                <div className="flex space-x-2 mt-3">
                  <img src={PhoneIcon} alt="" height={24} width={24} />
                  <span className="text-[#00173A] font-opensans text-base flex items-center">
                    <a href="tel:+19852390142">
                      US: <span id="number1">+1 985 239 0142</span>
                    </a>{" "}
                    &nbsp;
                    <img
                      src={CopyIcon}
                      className="ml-2.5 cursor-pointer h-fit"
                      onClick={() => handleCopy("number1")}
                      data-tooltip-id="copy-tooltip"
                      data-tooltip-content={copy}
                    />
                  </span>
                </div>
                <div className="flex space-x-2 mt-3">
                  <img src={PhoneIcon} alt="" height={24} width={24} />
                  <span className="text-[#00173A] font-opensans text-base flex items-center">
                    <a href="tel:+441615096140">
                      UK: <span id="number2">+44 (0)16 1509 6140</span>
                    </a>
                    <img
                      src={CopyIcon}
                      className="ml-2.5 cursor-pointer h-fit"
                      onClick={() => handleCopy("number2")}
                      data-tooltip-id="copy-tooltip"
                      data-tooltip-content={copy}
                    />
                  </span>
                </div>
                <div className="flex space-x-2 mt-3">
                  <img src={MsgIcon} alt="" height={24} width={24} />
                  <span className="text-[#00173A] font-opensans  text-base">
                    <a href="mailto:support@tomedes.com" id="email">
                      support@tomedes.com
                    </a>
                  </span>
                  <img
                    src={CopyIcon}
                    className="ml-2.5 cursor-pointer h-fit mt-1"
                    onClick={() => handleCopy("email")}
                    data-tooltip-id="copy-tooltip"
                    data-tooltip-content={copy}
                  />
                </div>
              </div>
              <div className="mt-12 block sm:hidden ">
                <p className="text-[#00173A] font-opensans text-center text-sm">
                  Trusted by Satisfied Clients Worldwide
                </p>
                <div className="top-3 mt-4 grid grid-cols-3 place-content-center gap-x-12 gap-y-5">
                  <img
                    src={Google}
                    alt="google"
                    className="w-[79px] h-[26px]"
                  />
                  <img src={Microsoft} alt="microsoft" />
                  <img src={Adidas} alt="adidas" />
                  <img src={Ikea} alt="ikea" className="w-[56px] h-[22px]" />
                  <img src={MGM} alt="mgm" />
                  <img src={Nvidia} alt="nvidia" className="w-[75px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-midBlue">
          <ThankYouPopup open={open} setOpen={setOpen} />
          <div className="main_div flex flex-col justify-center px-5 mx-auto font-primary max-w-5xl">
            <div className="lg:w-8/12 lg:p-4 py-4">
              <h1 className="font-secondary text-white font-semibold my-4 py-2 text-45 lg:text-4xl inline-block custom-bg-pos">
                <span className="text-orange">Get in touch</span> with us
              </h1>
              <h2 className="text-white my-4 text-new leading-loose font-opensans">
                We will respond to your message within a few minutes.
                <br />
                You can also contact us on +1 985 239 0142 or +44 (0)16 1509
                6140
              </h2>
            </div>
            <div className="flex-wrap md:w-full m-auto px-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <label
                      className="block tracking-wide  text-white text-lg font-opensans mb-2"
                      htmlFor="first_name"
                    >
                      First Name
                      <span className="text-red">*</span>
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                      onClick={() => setCaptchaVisible(true)}
                      placeholder="Your first name"
                      name="first_name"
                      {...register("first_name", { required: true })}
                    />
                    {errors.first_name && (
                      <span className="text-red">{errorMessage}</span>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block  tracking-wide text-white text-lg font-opensans mb-2"
                      htmlFor="last_name"
                    >
                      Last Name
                      <span className="text-red">*</span>
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                      placeholder="Your last name"
                      name="last_name"
                      {...register("last_name", { required: true })}
                    />
                    {errors.last_name && (
                      <span className="text-red">{errorMessage}</span>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 md:mb-0">
                    <label
                      className="block  tracking-wide text-white text-lg font-opensans mb-2"
                      htmlFor="email"
                    >
                      Email
                      <span className="text-red">*</span>
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      type="email"
                      placeholder="Your email address"
                      name="email"
                      {...register("email", {
                        required: true,
                        pattern: patternEmail,
                      })}
                    />
                    {errors.email && (
                      <span className="text-red">{errorMessageEmail}</span>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3 ">
                    <label
                      className="block  tracking-wide text-white text-lg font-opensans mb-2"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <div className="flex flex-col">
                      <IntlTelInput
                        containerClassName="intl-tel-input w-full"
                        inputClassName="appearance-none inline w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        onPhoneNumberChange={(
                          status,
                          value,
                          countryData,
                          number,
                          id
                        ) => {
                          setPhonenumber(value);
                        }}
                        onSelectFlag={(num, country) => {
                          setCountrycode(country.dialCode);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block  tracking-wide text-white text-lg font-opensans mb-2"
                      htmlFor="company"
                    >
                      Company
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                      placeholder="Company"
                      name="company"
                      {...register("company")}
                    />
                    {errors.company && (
                      <span className="text-red">{errorMessage}</span>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block font-opensans  tracking-wide text-white text-lg  mb-2"
                      htmlFor="country_id"
                    >
                      Country
                    </label>
                    <select
                      {...register("country_id")}
                      id="countries"
                      name="country_id"
                      className="appearance-none block w-full bg-white text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option
                        className="text-gray-700 text-2xl font-opensans"
                        disabled
                        selected
                      >
                        Select Country
                      </option>
                      {captchaVisible && CountryArray
                        ? CountryArray.map(({ name }, index) => (
                            <option
                              key={index}
                              value={name}
                              className="text-gray-700 text-2xl font-opensans"
                            >
                              {name}
                            </option>
                          ))
                        : null}
                    </select>
                    {/* {errors.country_id && (
                    <span className="text-red pt-3">{errorMessage}</span>
                  )} */}
                    {errors.country_id && (
                      <span className="text-red pt-3">
                        {errors.country_id.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3">
                  <div className="w-full md:w-full px-3 md:mb-0">
                    <label
                      className="block  tracking-wide text-white text-lg font-opensans mb-2"
                      htmlFor="message"
                    >
                      Tell Us More About Your Needs:
                    </label>
                    <textarea
                      className="appearance-none block w-full bg-gray-200 text-gray-700 text-2xl font-opensans border border-grayInput  py-1.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                      rows="4"
                      placeholder="A member of our team will contact you within a few minutes"
                      name="message"
                      {...register("message")}
                    />
                  </div>
                </div>
                {/* {captchaVisible && (
                  <HCaptcha
                    sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
                    onVerify={handleVerify}
                    ref={captchaRef}
                  />
                )} */}
                <div className="flex justify-center">
                  <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                  <button
                    type="submit"
                    className="bg-orange text-new text-white py-2 px-10 mb-4 rounded-full mx-auto cursor-pointer font-opensans mt-6"
                  >
                    {status === "loading" ? <Loader /> : "SUBMIT"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
